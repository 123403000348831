import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"

export default class Lightbox extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
  }

  constructor(props) {
      super(props)


      this.state = {
        showLightbox: false,
        selectedImage: null,
        selectedIndex: 0,
        hasPrevious: false,
        hasNext: true
       }

       this.handleKeyboardInput = this.handleKeyboardInput.bind(this);

       if (this.props.keyboard) {
         //       window.addEventListener('keydown', this.handleKeyboardInput);
       }
    }

    componentDidMount() {
      window.addEventListener('keydown', this.handleKeyboardInput);
    }

    componentWillUnmount() {
      window.removeEventListener('keydown', this.handleKeyboardInput);
    }

handleKeyboardInput(event) {
  // this.setState(state => ({
  //     showLightbox: !state.showLightbox
  //   }));
      event = event || window.event
      const { images } = this.props
      if (this.state.hasPrevious && event.keyCode === 37) {
          this.setState(state => ({
            showLightbox: state.showLightbox,
            selectedIndex: state.selectedIndex-1,
            selectedImage: images[state.selectedIndex-1],
            hasPrevious: state.selectedIndex-1 === 0 ? false : true,
            hasNext: true,
          }))
      }
      if (this.state.hasNext && event.keyCode === 39) {
          this.setState(state => ({
            showLightbox: state.showLightbox,
            selectedIndex: state.selectedIndex+1,
            selectedImage: images[state.selectedIndex+1],
            hasPrevious: true,
            hasNext: state.selectedIndex+1 === images.length - 1 ? false : true,
          }))
      }
      if (event.keyCode === 27) {
        this.setState({ showLightbox: false })
      }
    }

  render() {
    const { images } = this.props
    const { selectedIndex, selectedImage, showLightbox, hasPrevious, hasNext } = this.state
    return (
      <Fragment>
        <div className="photo-grid" >
          {images.map((image, index) => (
            <button style={{
              background: 'transparent',
              border: 'none',
              padding: 0,
              margin: 0,
            }}
            key={image.node.childImageSharp.fluid.src}
            type="button"
            onClick={() =>
              this.setState({
                showLightbox: true,
                selectedImage: image,
                selectedIndex: index,
                hasPrevious: index === 0 ? false : true,
                hasNext: index === images.length - 1 ? false : true,
               })
            }
            >
            <Image
            style={{
              height: '100%',
              maxHeight: '15rem'
            }}
            fluid={image.node.childImageSharp.fluid}
            />
            </button>
          ))}
        </div>
        {showLightbox && (
          <div style={{
            width: '100%',
            height: '100%',
            background: '#000D',
            position: 'fixed',
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
          onClick={() => this.setState({ showLightbox: false })}
          className={'lightbox'}
            >
            <div style={{
              position: 'fixed',
              width: '90%',
              height: '90%',
            }}
            >
              <Image
                fluid={selectedImage.node.childImageSharp.fluid}
                imgStyle={{
                  objectFit: 'contain',
                  objectPosition: '50% 50%',
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  borderRadius: `0.2em`,
                }}
              />
              <button
                type="button"
                onClick={() => this.setState({ showLightbox: false })}
                className="close"
                style={{
                  bottom: "auto",
                  right: 0,
                  top: 0,
                  height: '3rem'
                }}
              >
                <i className="fas fa-times"></i>
              </button>
              {hasNext && (
                <button
                  type="button"
                  onClick={(e) => {
                    this.setState({
                      showLightbox: true,
                      selectedIndex: selectedIndex+1,
                      selectedImage: images[selectedIndex+1],
                      hasPrevious: true,
                      hasNext: selectedIndex+1 === images.length - 1 ? false : true,
                    })
                    e.stopPropagation()
                  }}
                  className="next"
                  style={{
                    right: 0,
                    marginRight: '1rem',
                  }}
                >
                  <i className="fas fa-arrow-right"></i>
                </button>
              )}
              {hasPrevious && (
                <button
                  type="button"
                  onClick={(e) => {
                    this.setState({
                      showLightbox: true,
                      selectedIndex: selectedIndex-1,
                      selectedImage: images[selectedIndex-1],
                      hasPrevious: selectedIndex-1 === 0 ? false : true,
                      hasNext: true,
                    })
                    e.stopPropagation()
                  }}
                  className="previous"
                  style={{
                    left: 0,
                    marginLeft: '1rem',
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
              )}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
